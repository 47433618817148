import React, { Component } from 'react';
import { HashRouter, Route, Switch, BrowserRouter } from 'react-router-dom';
import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages


class App extends Component {

  render() {
    return (
      <HashRouter>
       {/* <BrowserRouter > */}
          <React.Suspense fallback={loading}> 
                <Switch>     
                  <Route  path="/" name="Home" render={props => <TheLayout {...props}/>} />
                </Switch> 
          </React.Suspense>
           {/* </BrowserRouter> */}
      </HashRouter>
    );
  }
}

export default App;
